










import { mapGetters } from 'vuex';
import CopyStakeWebStreamerSettingsForm from '@/views/CopyStake/CopyStakeWebStreamer/CopyStakeWebStreamerSettingsForm.vue';
import CopyStakeWebStreamersPendingTable from '@/views/CopyStake/CopyStakeWebStreamer/CopyStakeWebStreamersPendingTable.vue';
import CopyStakeWebStreamersTable from '@/views/CopyStake/CopyStakeWebStreamer/CopyStakeWebStreamersTable.vue';
import FeatureFlagRedirectMixin from '@/mixins/copy-stake/FeatureFlagRedirectMixin';
import { ECopyStakeFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

export default {
  name: 'CopyStakeWebStreamersView',
  mixins: [FeatureFlagRedirectMixin],
  components: {
    CopyStakeWebStreamersPendingTable,
    CopyStakeWebStreamersTable,
    CopyStakeWebStreamerSettingsForm
  },
  computed: {
    ...mapGetters('FeatureFlags', ['isAdminVisibleFeatureFlag']),
    isFeatureVisible(): boolean {
      return this.isAdminVisibleFeatureFlag(
        ECopyStakeFeatureFlag.WEB_STREAMERS
      );
    }
  },
  data(): Record<string, unknown> {
    return {
      needToRefetchTableData: false
    };
  },
  methods: {
    handleAcceptedCopyStakeWebStreamersPendingTable(): void {
      this.needToRefetchTableData = true;
    }
  }
};
