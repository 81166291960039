





































import { mapGetters } from 'vuex';

import {
  getStreamConfiguration,
  updateStreamConfiguration
} from '@/api/CopyStake/Configuration';
import { TStreamConfigurationResponse, ValidationRule } from '@/api/schema';

import { positiveNumber, validNumber, integer } from '@/helpers/validation';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  name: 'CopyStakeWebStreamerSettingsForm',
  data(): any {
    return {
      inactivityTimeout: '',
      inactivityTimeoutValue: '',
      isLoading: false,
      formValid: false
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),

    canEdit(): boolean {
      return this.$role.can.update('copy_stake streamers');
    },
    inactivityTimeoutChanged(): boolean {
      return `${this.inactivityTimeoutValue}` !== `${this.inactivityTimeout}`;
    },
    valueCanBeSaved(): boolean {
      return this.formValid && this.inactivityTimeoutChanged;
    },
    inactivityTimeoutRules(): ValidationRule[] {
      return [
        (v: any) => v === '' || positiveNumber(v),
        (v: any) => v === '' || validNumber(v),
        (v: any) => v === '' || integer(v)
      ];
    }
  },
  watch: {
    operatorId: {
      handler: 'getConfiguration',
      immediate: true
    }
  },
  methods: {
    validateForm(): void {
      this.$refs.form.validate();
    },
    async getConfiguration(): Promise<void> {
      if (!this.operatorId) {
        return;
      }

      this.isLoading = true;

      getStreamConfiguration()
        .then((data: TStreamConfigurationResponse) => {
          this.inactivityTimeoutValue = data.webStreamerInactiveTimeout || '';
          this.inactivityTimeout = data.webStreamerInactiveTimeout || '';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async updateConfiguration(): Promise<void> {
      const payload: Partial<TStreamConfigurationResponse> = {};
      this.isLoading = true;

      if (this.inactivityTimeoutChanged) {
        payload.webStreamerInactiveTimeout = this.inactivityTimeoutValue;
      }

      await updateStreamConfiguration(payload)
        .then(() => {
          this.inactivityTimeout = this.inactivityTimeoutValue;

          this.$toast.success('Settings was successfully updated!');
        })
        .catch(errorToastMessage)
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
