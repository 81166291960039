


































































































import { mapGetters } from 'vuex';
import { DataTableHeader } from 'vuetify';
import { textOverflowMiddle, truncateString } from '@/helpers/formatString';

const ImagePreviewDialog = (): Promise<any> =>
  import('@/components/ImagePreviewDialog.vue');
const CopyAddress = (): Promise<any> =>
  import('@/components/CopyAddress.vue');
const VStatus = (): Promise<any> => import('@/components/VStatus.vue');
const ConfirmationDialog = (): Promise<any> =>
  import('@/components/ConfirmationDialog.vue');
const CopyStakeWebStreamerDialog = (): Promise<any> =>
  import(
    '@/views/CopyStake/CopyStakeWebStreamer/CopyStakeWebStreamerDialog.vue'
  );

import {
  deleteStreamer,
  getStreamersList,
  updateStreamer
} from '@/api/CopyStake/Streamers';
import {
  TStreamer,
  EStreamerStatus,
  EStreamerType,
  EStreamingType,
  EStreamingModerationStages
} from '@/api/schema';

import { getLangLabel } from '@/helpers/langHelpers';
import { addThumbnailPrefix } from '@/helpers/copyStakeHelpers';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { objectToBlob, urlToBlob } from '@/helpers/blobHelper';
import CopyStakeWebStreamersStats from '@/views/CopyStake/CopyStakeWebStreamer/CopyStakeWebStreamersStats.vue';

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  itemsPerPage: 10
};
const STREAMER_TYPE_MAP = {
  [EStreamerType.PRIVATE]: {
    label: 'Private',
    type: 'system-success'
  },
  [EStreamerType.PUBLIC]: {
    label: 'Public',
    type: 'additional-finished'
  }
};
const STREAMING_TYPE_MAP = {
  [EStreamingType.WHIP]: {
    label: 'WHIP',
    type: 'additional-progress'
  },
  [EStreamingType.WEB]: {
    label: 'WEB',
    type: 'additional-new'
  }
};

export default {
  name: 'CopyStakeWebStreamersTable',
  components: {
    CopyStakeWebStreamersStats,
    ImagePreviewDialog,
    CopyStakeWebStreamerDialog,
    ConfirmationDialog,
    CopyAddress,
    VStatus
  },
  props: {
    needToRefetch: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      EStreamingType,
      EStreamerStatus,
      STREAMER_TYPE_MAP,
      STREAMING_TYPE_MAP,
      isLoading: false,
      streamersList: [],
      total: null,
      tableOptions: { ...DEFAULT_PAGINATION_OPTIONS },
      dialogs: {
        imagePreview: false,
        edit: false,
        confirm: false,
        loading: false,
        data: undefined
      }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    canEdit(): boolean {
      return this.$role.can.update('copy_stake streamers');
    },
    headers(): DataTableHeader[] {
      return [
        { text: 'Date Added', value: 'createdAt' },
        { text: 'Wallet Address', value: 'playerWalletHash' },
        { text: 'Operator User ID', value: 'operatorUserId' },
        ...(this.canEdit
          ? [
              {
                text: 'Manage Broadcast',
                value: 'active'
              }
            ]
          : []),
        { text: 'Channel ID', value: 'channelId' },
        // { text: 'Thumbnails 3x4\xa0/\xa016x9', value: 'images' },
        ...(this.canEdit
          ? [
              {
                text: 'Action',
                value: 'actions',
                width: 196,
                align: 'center'
              } as DataTableHeader
            ]
          : [])
      ];
    }
  },
  watch: {
    operatorId: {
      async handler(): Promise<void> {
        await this.fetchStreamersData();
      },
      immediate: true
    },
    needToRefetch: {
      async handler(): Promise<void> {
        if (this.needToRefetch) {
          await this.fetchStreamersData();
        }
      }
    }
  },
  methods: {
    getLangLabel,
    addThumbnailPrefix,
    textOverflowMiddle,
    truncateString,
    checkIsOnline(status: EStreamerStatus): boolean {
      return [EStreamerStatus.ONLINE, EStreamerStatus.BROADCAST].includes(
        status
      );
    },
    showDeleteDialog(streamer: TStreamer): void {
      this.dialogs.confirm = true;
      this.dialogs.data = {
        disclaimer: `
          <p>
            Are you sure you want to delete streamer <strong>${
              streamer.nickname || streamer.playerId
            }</strong>?
          </p>
          <p class="text--disabled">You will not be able to recover it.</p>`,
        icon: 'mdi-alert-octagon',
        header: 'Delete Streamer',
        okText: 'Delete',
        handler: () => this.handlerOnDeleteStreamer(streamer)
      };
    },
    showChangeStreamerBroadcastStatusDialog(streamer: TStreamer): void {
      const disclaimer = streamer.active
        ? `Are you sure you want to stop broadcasting for <strong>${
            streamer.nickname || streamer.playerId
          }</strong>?`
        : `Are you sure you want to start broadcasting for <strong>${
            streamer.nickname || streamer.playerId
          }</strong>?`;
      const header = streamer.active
        ? 'Stop Broadcasting'
        : 'Start Broadcasting';
      const okText = streamer.active ? 'Stop' : 'Start';
      const okButtonType = streamer.active ? 'primary' : 'error';

      this.dialogs.confirm = true;
      this.dialogs.data = {
        disclaimer,
        header,
        okText,
        okButtonType,
        handler: () => this.handlerOnStreamerBroadcastStatusChange(streamer)
      };
    },
    handlerOpenGameImagePreviewDialog(imageUrl?: string): void {
      if (!imageUrl) {
        return;
      }

      this.dialogs.imagePreview = true;
      this.dialogs.data = { imageUrl };
    },
    async handlerOnDeleteStreamer(streamer: TStreamer): Promise<void> {
      try {
        this.dialogs.loading = true;

        await deleteStreamer(streamer.id);
        this.fetchStreamersData();
        this.$toast.success(
          `Streamer "${
            streamer.nickname || streamer.playerId
          }" was successfully deleted`
        );
        this.closeDialog('confirm');
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.dialogs.loading = false;
      }
    },
    handlerOnEditStreamer(streamer?: TStreamer): void {
      this.dialogs.edit = true;

      if (streamer) {
        this.dialogs.data = streamer;
      }
    },
    closeDialog(type: string, resetData: boolean = true): void {
      this.dialogs[type] = false;

      if (resetData) {
        this.$nextTick(() => {
          this.dialogs.data = {};
        });
      }
    },
    async fetchStreamersData(): Promise<void> {
      if (!this.operatorId) return;

      try {
        this.isLoading = true;

        const { content, totalElements } = await getStreamersList({
          size: this.tableOptions.itemsPerPage,
          page: this.tableOptions.page - 1,
          operatorId: this.operatorId,
          streamingType: EStreamingType.WEB,
          moderationStages: [
            EStreamingModerationStages.ACCEPTED,
            EStreamingModerationStages.COMPLETED
          ]
        });

        this.streamersList = content;
        this.total = totalElements;

        this.$emit('update:needToRefetch', false);
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handlerOnStreamerBroadcastStatusChange(
      fullStreamer: TStreamer
    ): Promise<void> {
      try {
        this.isLoading = true;

        const { images, ...streamer } = fullStreamer;

        const formData = new FormData();
        formData.append(
          'streamer',
          objectToBlob({
            ...streamer,
            active: !streamer.active
          })
        );

        if (images?.THREE_FOUR) {
          const imageThreeFourBlob = await urlToBlob(images.THREE_FOUR);
          formData.append('imageThreeFour', imageThreeFourBlob);
        }

        if (images?.SIXTEEN_NINE) {
          const imageSixteenNineBlob = await urlToBlob(images.SIXTEEN_NINE);
          formData.append('imageSixteenNine', imageSixteenNineBlob);
        }

        await updateStreamer(streamer.id, formData);

        this.fetchStreamersData();
        this.$toast.success(
          `Streamer "${
            streamer.nickname || streamer.playerId
          }" broadcast status was successfully changed`
        );
        this.closeDialog('confirm');
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmitCopyStakeWebStreamerDialog(): Promise<void> {
      await this.fetchStreamersData();
    }
  }
};
