



















































import { mapGetters } from 'vuex';
import { DataTableHeader } from 'vuetify';

const CopyAddress = (): Promise<any> =>
  import('@/components/CopyAddress.vue');
const VStatus = (): Promise<any> => import('@/components/VStatus.vue');
const ConfirmationDialog = (): Promise<any> =>
  import('@/components/ConfirmationDialog.vue');

import {
  acceptStreamer,
  declineStreamer,
  getStreamersList
} from '@/api/CopyStake/Streamers';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import CopyStakeStreamersStats from '@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamersStats.vue';
import {
  EStreamingModerationStages,
  EStreamingType,
  TStreamer
} from '@/api/schema';

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  itemsPerPage: 10
};

export default {
  name: 'CopyStakeWebStreamersPendingTable',
  components: {
    CopyStakeStreamersStats,
    ConfirmationDialog,
    CopyAddress,
    VStatus
  },
  data(): any {
    return {
      isLoading: false,
      isProcessing: false,
      streamersList: [],
      total: 0,
      tableOptions: { ...DEFAULT_PAGINATION_OPTIONS },
      dialogs: {
        confirm: false,
        loading: false,
        data: undefined
      }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    canEdit(): boolean {
      return this.$role.can.update('copy_stake streamers');
    },
    headers(): DataTableHeader[] {
      return [
        { text: 'Date', value: 'createdAt' },
        { text: 'Operator User ID', value: 'operatorUserId' },
        ...(this.canEdit
          ? [
              {
                text: 'Action',
                value: 'actions',
                width: 196,
                align: 'center'
              } as DataTableHeader
            ]
          : [])
      ];
    }
  },
  watch: {
    operatorId: {
      handler: 'fetchStreamersData',
      immediate: true
    }
  },
  methods: {
    async fetchStreamersData(): Promise<void> {
      if (!this.operatorId || this.isLoading) {
        return;
      }

      try {
        this.isLoading = true;

        const { content, totalElements } = await getStreamersList({
          size: this.tableOptions.itemsPerPage,
          page: this.tableOptions.page - 1,
          operatorId: this.operatorId,
          streamingType: EStreamingType.WEB,
          moderationStages: [EStreamingModerationStages.REQUESTED]
        });

        this.streamersList = content;
        this.total = totalElements;
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog(type: string, resetData: boolean = true): void {
      this.dialogs[type] = false;

      if (resetData) {
        this.$nextTick(() => {
          this.dialogs.data = {};
        });
      }
    },
    async acceptStreamer(streamer: TStreamer): Promise<void> {
      if (this.isProcessing) {
        return;
      }

      try {
        this.isProcessing = true;
        await acceptStreamer(streamer.id);
        this.closeDialog('confirm');
        this.$toast.success(
          `Streamer "${streamer.operatorUserId}" was successfully accepted`
        );
        await this.fetchStreamersData();
        this.$emit('accepted', streamer);
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isProcessing = false;
      }
    },
    async declineStreamer(streamer: TStreamer): Promise<void> {
      if (this.isProcessing) {
        return;
      }

      try {
        this.isProcessing = true;
        await declineStreamer(streamer.id);
        this.closeDialog('confirm');
        this.$toast.success(
          `Streamer "${streamer.operatorUserId}" was successfully declined`
        );
        await this.fetchStreamersData();
        this.$emit('declined', streamer);
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isProcessing = false;
      }
    },
    async handleClickAcceptButton(streamer: TStreamer): Promise<void> {
      this.dialogs.confirm = true;
      this.dialogs.data = {
        disclaimer: `
          <p>
            Are you sure you want to accept user <strong>${streamer.operatorUserId}</strong>?
          </p>
        `,
        header: 'Accept User',
        okText: 'Accept',
        okButtonType: 'success',
        handler: () => this.acceptStreamer(streamer)
      };
    },
    async handleClickDeclineButton(streamer: TStreamer): Promise<void> {
      this.dialogs.confirm = true;
      this.dialogs.data = {
        disclaimer: `
          <p>
            Are you sure you want to decline user <strong>${streamer.operatorUserId}</strong>?
          </p>
        `,
        header: 'Decline User',
        okText: 'Decline',
        okButtonType: 'error',
        handler: () => this.declineStreamer(streamer)
      };
    }
  }
};
