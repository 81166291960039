var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"flex-grow-1",attrs:{"elevation":2}},[_c('div',{staticClass:"d-flex pa-5 justify-space-between"},[_c('CopyStakeWebStreamersStats'),(_vm.canEdit)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function () { return _vm.handlerOnEditStreamer(); }}},[_vm._v("Add Streamer +")]):_vm._e()],1),_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table-with-pagination',{staticClass:"streamer-table",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"options":_vm.tableOptions,"items":_vm.streamersList,"server-items-length":_vm.total,"mobile-breakpoint":1024,"disable-sort":""},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.fetchStreamersData]},scopedSlots:_vm._u([{key:"item.channelId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"streamer-table__status mr-1",class:{'streamer-table__status--online': _vm.checkIsOnline(item.status)}},'div',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"streamer-table__status-text text-capitalize",domProps:{"textContent":_vm._s(item.status.toLocaleLowerCase())}})]),_vm._v(_vm._s(_vm.textOverflowMiddle(item.channelId, 3, 3)))],1)]}},{key:"item.playerWalletHash",fn:function(ref){
var item = ref.item;
return [_c('CopyAddress',{attrs:{"address":item.playerWalletHash,"start":4,"end":4,"fallback":'---'}})]}},{key:"item.operatorUserId",fn:function(ref){
var item = ref.item;
return [_c('CopyAddress',{attrs:{"address":item.operatorUserId,"start":4,"end":4,"fallback":'---'}})]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","disabled":!item.images.THREE_FOUR},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.handlerOpenGameImagePreviewDialog(item.images.THREE_FOUR)}}},[(item.images.THREE_FOUR)?_c('div',{staticClass:"streamer-table__streamer-preview",style:({ backgroundImage: ("url('" + (_vm.addThumbnailPrefix(item.images.THREE_FOUR)) + "')") })}):_c('v-icon',[_vm._v("mdi-image")])],1),_c('v-btn',{attrs:{"icon":"","disabled":!item.images.SIXTEEN_NINE},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.handlerOpenGameImagePreviewDialog(item.images.SIXTEEN_NINE)}}},[(item.images.SIXTEEN_NINE)?_c('div',{staticClass:"streamer-table__streamer-preview",style:({ backgroundImage: ("url('" + (_vm.addThumbnailPrefix(item.images.SIXTEEN_NINE)) + "')") })}):_c('v-icon',[_vm._v("mdi-image")])],1)],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.active,"loading":_vm.isLoading,"label":"Play","readonly":""},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.showChangeStreamerBroadcastStatusDialog(item)}}})]}},(_vm.canEdit)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"streamer-table__action streamer-table__action--delete mr-1",attrs:{"icon":""},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.showDeleteDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1),_c('v-btn',{staticClass:"streamer-table__action streamer-table__action--edit",attrs:{"icon":""},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.handlerOnEditStreamer(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}:null],null,true)})],1),(_vm.canEdit)?[_c('ConfirmationDialog',{attrs:{"processing":_vm.dialogs.loading,"data":_vm.dialogs.data,"closable":""},on:{"close":function () { return _vm.closeDialog('confirm'); }},model:{value:(_vm.dialogs.confirm),callback:function ($$v) {_vm.$set(_vm.dialogs, "confirm", $$v)},expression:"dialogs.confirm"}}),_c('CopyStakeWebStreamerDialog',{attrs:{"streamer":_vm.dialogs.data},on:{"submit":_vm.handleSubmitCopyStakeWebStreamerDialog,"close":function (clear) { return _vm.closeDialog('edit', clear); }},model:{value:(_vm.dialogs.edit),callback:function ($$v) {_vm.$set(_vm.dialogs, "edit", $$v)},expression:"dialogs.edit"}})]:_vm._e(),(_vm.dialogs.imagePreview)?_c('ImagePreviewDialog',{attrs:{"image":_vm.dialogs.data.imageUrl},on:{"close":function () { return _vm.closeDialog('imagePreview'); }},model:{value:(_vm.dialogs.imagePreview),callback:function ($$v) {_vm.$set(_vm.dialogs, "imagePreview", $$v)},expression:"dialogs.imagePreview"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }