


















import { mapGetters } from 'vuex';
import { getStreamerOnlineLimits } from '@/api/CopyStake/Configuration';

import StatsCard from '@/components/StatsCard.vue';
export default {
  name: 'CopyStakeStreamersStats',
  components: {
    StatsCard
  },
  data(): any {
    return {
      loading: false,
      streamers: {
        online: 0,
        limit: 0
      },
      watchers: {
        online: 0,
        limit: 0
      }
    };
  },

  methods: {
    getStats(): void {
      this.loading = true;

      getStreamerOnlineLimits()
        .then((response) => {
          this.streamers = response.streamers;
          this.watchers = response.watchers;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  computed: {
    ...mapGetters('Onboarding', ['operatorId'])
  },

  watch: {
    operatorId: {
      handler(id: number): void {
        if (id && !this.loading) this.getStats();
      },
      immediate: true
    }
  }
};
