import { http } from '@/api/Connect';
import { BASE_WEB_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

import {
  EStreamingType,
  ICopyStakeStatsResponse,
  TStreamConfigurationResponse
} from '@/api/schema';

const API_WEB_SETTINGS = (): string => `${BASE_WEB_OPERATOR()}/settings`;

export async function getStreamConfiguration(): Promise<TStreamConfigurationResponse> {
  return http
    .get(API_WEB_SETTINGS())
    .then(({ data }: AxiosResponse<TStreamConfigurationResponse>) => data);
}

export async function updateStreamConfiguration(
  payload: Partial<TStreamConfigurationResponse>
): Promise<TStreamConfigurationResponse> {
  return http
    .put(API_WEB_SETTINGS(), payload)
    .then(({ data }: AxiosResponse<TStreamConfigurationResponse>) => data);
}

export async function getStreamerOnlineLimits(): Promise<ICopyStakeStatsResponse> {
  return http
    .get(
      `${API_WEB_SETTINGS()}/online-limits?streamingType=${EStreamingType.WHIP}`
    )

    .then(({ data }: AxiosResponse): ICopyStakeStatsResponse => data);
}

export async function getWebStreamerOnlineLimits(): Promise<ICopyStakeStatsResponse> {
  return http
    .get(
      `${API_WEB_SETTINGS()}/online-limits?streamingType=${EStreamingType.WEB}`
    )
    .then(({ data }: AxiosResponse): ICopyStakeStatsResponse => data);
}
